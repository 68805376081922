<template>
<div class="fallback common-page">
    <div class="centered">
        <img src="" alt="" srcset="">
        <span class="tip">正在施工中</span>
    </div>
</div>
</template>

<script>
export default {
name: "",
components: {},
data() {
return {

};
},
computed: {},
watch: {},
methods: {},
created(){},
mounted(){},
beforeDestroy(){}
};
</script>

<style scoped>
.fallback{
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>